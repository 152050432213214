import Link from 'next/link';
import Head from "next/head";

export default function Custom404() {
    return (
        <div className="min-h-screen bg-gray-100 dark:bg-gray-900 py-6 flex flex-col justify-center sm:py-12">
            <Head>
                <title>404 | deinSEOExpert</title>
                <link rel="icon" href="/favicon.ico"/>
                <meta
                    name="description"
                    content="Anscheinend ist unsere SEO zu gut! Die Seite, die du suchst, hat sich in der Unendlichkeit des Internets versteckt."
                />
            </Head>
            <div className="relative py-3 sm:max-w-xl sm:mx-auto">
                <div className="absolute inset-0 bg-gradient-to-r from-green-400 to-blue-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
                <div className="relative px-4 py-10 bg-white dark:bg-gray-800 shadow-lg sm:rounded-3xl sm:p-20">
                    <h1 className="text-4xl font-bold text-gray-900 mb-4 dark:text-white">404 - Oops! 🙈</h1>
                    <p className="text-gray-400 mb-8">Anscheinend ist unsere SEO zu gut! Die Seite, die du suchst, hat sich in der Unendlichkeit des Internets versteckt.</p>
                    <Link href="/" className="text-green-500 hover:text-green-700 transition duration-300">
                        <button className="px-6 py-3 text-white bg-blue-900 rounded-md hover:bg-blue-700 transition duration-300">
                            Zurück zum SEO-Safe-Space
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
}
